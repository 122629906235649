<template>
  <div class="sMianTop">
    <div class="sMianTopText">
      <div class="sMianTopTextBox">
        <van-icon
          :size="60"
          :color="salesStatus[getStatus].color"
          :name="salesStatus[getStatus].icon"
        />
        <div class="sMianTopTextBoxText">
          {{ salesStatus[getStatus].text }}
        </div>
      </div>
      <!-- <div class="sMianTopTextBox">
        <van-button  style="btColor" block v-if="getStatus == '0'" color="#FFA54F"  @click="goHome">重新认证</van-button
        >
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "successStatus",
  data() {
    return {
      salesStatus: {
        // 0: { text: "实名认证失败", color: "#FF0000", icon: "clear" },
        1: { text: "实名认证成功", color: "#1E90FF", icon: "checked" },
      },
      getStatus: '', // 后端返回的成功或者失败状态
      resultObject: {},
    };
  },
  created() {
    this.getStatus = this.$route.query.resultStatus
  },
  mounted() {},
  methods: {
    goHome(){
      this.$router.push({name:'inforMationcC',query: { bizUserId: this.$route.query.bizUserId }})
    }
  },
};
</script>

<style scoped lang="scss">
.sMianTop {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  .sMianTopText {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    .sMianTopTextBox {
      margin: 30px 0;
      display: flex;
      flex-direction: column;
      .sMianTopTextBoxText {
        font-size: 20px;
        margin-top: 10px;
        font-weight: 400;
      }
    }
  }
}
.van-button {
  width: 200px;
  margin: 10px 0;
}
</style>
